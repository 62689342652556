<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col :span="6">
        <!-- <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить</el-button> -->
        <router-link :to="{ name: 'AdministrationNewsItem' }">
            <el-button type="text" icon="el-icon-circle-plus-outline" >Добавить</el-button>
        </router-link>
      </el-col>
      <el-col :span="12">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-table v-loading="loading"
                :data="news"
                style="width: 100%;"
                :height="tableHeight"
                :default-sort = "{prop: 'notificationTime', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column label="" width="50">
          <template slot-scope="props">
            <router-link :to="{ name: 'AdministrationNewsItem', params: { id: props.row.id } }">
                <el-button type="text" icon="el-icon-edit" />
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="header"
                         label="Заголовок"
                         sortable="custom"
                         min-width="75">
        </el-table-column>
        <!-- <el-table-column prop="description"
                         label="Содержание"
                         min-width="25">
            <template slot-scope="scope" v-if="scope.row.description">
                <div class="exception-container">
                    {{ scope.row.description.length > 114 ? scope.row.description.slice(0,115) + '...' : scope.row.description}}
                </div>
            </template>
        </el-table-column> -->
        <el-table-column prop="notificationTime"
                         label="Дата и время публикации"
                         sortable="custom"
                         min-width="25"
                         :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column label="" width="50">
            <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import newsApi from '@/api/news';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize';

  export default {
    name: 'AdministrationNews',
    mixins: [formatters, tableResize],
    components: { pagination },
    data() {
      return {
        loading: false,
        news: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        sortField: 'notificationTime',
        sortDesc: true
      };
    },    
    async mounted() {
      this.$nextTick(() => {
        this.tableHeight = this.getTableHeight();
      });
      await this.getAdministrationNews();
      this.tableHeight = this.getTableHeight();
    },
    methods: {
    ...mapActions('news', [
      'makeNewsRead', 'makeAllUserNewsRead', 'deleteNews'
    ]),
      async getAdministrationNews() {
        this.loading = true;
        const res = await newsApi.getAdministrationNews(
          this.pageNumber,
          this.pageSize,
          this.sortField,
          this.sortDesc
        );
        if (res.data) {
          this.news = res.data.items;
          this.totalCount = res.data.totalCount;
        }
        this.loading = false;
      },
      async handleSizeChange() {
        this.pageNumber = 1;
        await this.getAdministrationNews();
      },
      async handleCurrentChange() {
        await this.getAdministrationNews();
      },
      async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getAdministrationNews();
      },
      // eslint-disable-next-line no-unused-vars
      handleDelete(index, news) {
        this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          this.loading = true;
          const res = await newsApi.deleteNews(news.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            // await this.deleteNews();
            await this.getAdministrationNews();
          }          
          this.loading = false;
        });
      },
    }
  };
</script>

<style scoped>
.el-table:before {
  height: 0px;
}
</style>
